
import { useContext } from "react";

// GLOBAL STATES
import DataContext from '../globalstates/DataContext';

// ROUTER
import { useParams } from 'react-router-dom';

// COMPONENTS
import CastingContent from "../components/CastingContent";
import NeedLogin from "../components/NeedLogin";

export default function CastingPage() {

    // ROUTER
    const params = useParams()

    // GLOBAL STATES
    const { logedIn, thisData } = useContext(DataContext);

    return(
        <>
            {logedIn.login === params.id && logedIn.loggedIn === true
            ? <CastingContent thisData={thisData}/>
            : null
            }           

            {logedIn.login != params.id || logedIn.loggedIn === false
            ? <NeedLogin />
            : null
            }   
        </>
    )
}