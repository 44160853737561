import React, { useEffect, useState } from "react";

// LOCAL STORAGE LIBRARY
import useLocalStorageState from 'use-local-storage-state';

// ROUTER
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// GLOBAL STATES
import DataContext from './globalstates/DataContext';

// PAGES
import LandingPage from "./pages/LandingPage";
import ClientPage from "./pages/ClientPage";
import ProductionDesPage from "./pages/ProductionDesPage";
import WardrobePage from "./pages/WardrobePage";
import CastingPage from "./pages/CastingPage";

import FileTablePage from "./pages/FileTablePage";
import LocationPage from "./pages/LocationPage";

import { db } from "./config/firebase";
import { getDocs, collection } from "firebase/firestore";

import useGetData from "./hooks/useGetData";


// ROUTES
const router = createBrowserRouter([
  {
    path: "/",
    exact: true,
    element: <LandingPage />,
  },

  {
    path: "/client/:id",
    exact: true,
    element: <ClientPage />,
  },



  {
    path: "/client/:id/:location",
    exact: true,
    element: <LocationPage />,
  },
  

  {
    path: "/client/:id/:location/:folder",
    exact: true,
    element: <FileTablePage />,
  },



  {
    path: "/client/:id/production-design",
    exact: true,
    element: <ProductionDesPage />,
  },

  {
    path: "/client/:id/wardrobe",
    exact: true,
    element: <WardrobePage />,
  },

  {
    path: "/client/:id/casting",
    exact: true,
    element: <CastingPage />,
  },
]);

export const defaultLogedIn = {
  login: null,
  password: null,
  loggedIn: false,
}


export default function App() {


  const [logedIn, setLoggedIn] = useLocalStorageState('logedIn', {defaultValue: defaultLogedIn});

  const [comeFrom, setComeFrom] = useLocalStorageState('comefrom', {defaultValue: "/"});

  const [thisData, setThisData] = useState(undefined)

  const check = useGetData(logedIn.login, logedIn.password);



useEffect(() => {
  check && setThisData(check)
  if(check === false) {
    setLoggedIn(defaultLogedIn)
  }
}, [check])  



  return (
    <>
    <DataContext.Provider value={{logedIn, setLoggedIn, comeFrom, setComeFrom, thisData}}>
      <RouterProvider router={router} />
    </DataContext.Provider>  
    </>
  );
}

