import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

// ICONS
import closeIcon from "../pictures/icons/close.svg";

import { getHeight } from "../functions/HelpingFunctions";

export default function FileTableNew({ projectName, thisData }) {

    const navigate = useNavigate();
    const params = useParams();

    // SCALING
    const [scale, setScale] = useState(1);

    function changeScale() {
        setScale(getHeight());
    }

    // SCALE AFTER LOAD
    useEffect(() => {
        changeScale()
    }, [])

    // CHANGE SCALE ON RESIZE
    useEffect(() => {
        window.addEventListener("resize", changeScale);

        return() => {
            window.removeEventListener("resize", changeScale);
        }
    }, [])


    return(
        <div
        onClick={() => navigate("/client/" + params.id + "/" + params.location )}
        style={{
            background: "rgba(0,0,0,0.8)",
            width: "100%",
            position: "fixed",
            zIndex: "100",
            height: "100vh"
        }}>          
            <div className="flex-col align-center">

                <div className="content-container"
                style={{
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top",
                }}>
                    

                    <div
                        style={{
                            position: "absolute",
                            transform: "translate(0px, -578px)",
                            left: "134px",
                            pointerEvents: "none"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "16px",
                                fontFamily: "Roboto",
                                color: "#909090",
                                letterSpacing: "0.06em",
                                fontWeight: "600"
                            }}
                        >{"* " + (projectName)?.toUpperCase()}</span>
                    </div>


                    <div
                        style={{
                            position: "absolute",
                            transform: "translate(0px, -578px)",
                            //right: "1500px",
                            pointerEvents: "none"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "16px",
                                fontFamily: "Roboto",
                                color: "#909090",
                                letterSpacing: "0.06em",
                                fontWeight: "600",
                                //background: "#212121",
                                padding: "5px"
                            }}
                        >{params.location.toUpperCase() + " / " + params.folder.toUpperCase()}</span>
                    </div>

                    
                    <button
                    onClick={() => navigate("/client/" + params.id + "/" +  params.location)}
                    className="filetable-close"
                    >
                        <img src={closeIcon} width="40" height="40"/>
                    </button>


                    <iframe
                        src={thisData}
                        style={{
                            width: "2120px",
                            height: "1200px",
                            border: "none",
                            borderRadius: "22px"
                        }}
                    ></iframe>

                </div>
            </div>
        </div>

    )
}